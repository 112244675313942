import Typography from '@mui/material/Typography'
import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const NewsletterPage: React.FC = () => {
	return (
		<Layout>
			<Seo title="The blog" description="The dev tavern blog posts" />
			<Typography fontWeight="bold" variant="h1" gutterBottom>The newsletter</Typography>
			<Typography fontWeight="bold" variant="h4" my="2rem" mt="4rem">Coming soon! 🚧</Typography>
		</Layout>
	)
}


export default NewsletterPage